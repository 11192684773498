import useSWR from "swr";
import { getProfile } from "../utils/auth-provider";

import { UserProfile } from "~/src/types/auth";
import { ApiClientError } from "~/src/utils/api-client";
import { useAppNavigator } from "../router";

export function useUserProfile() {
  const { go } = useAppNavigator();

  const { data, isLoading, isValidating, error } = useSWR<UserProfile, ApiClientError>("user-profile", getProfile, {
    keepPreviousData: true,
    revalidateIfStale: false,
    onError(err) {
      go.login();
    },
  });

  return {
    profile: data ?? {
      username: "guest",
      role: {
        type: "public",
      },
    },
    isLoading,
    isValidating,
    isError: !!error,
    error,
  };
}
