import { useRouteError } from "react-router-dom";
import { Layout } from "~/src/components/layout";

export default function ErrorPage() {
  const error = useRouteError() as Error;

  return (
    <Layout
      center={
        <div>
          <h1>Well, well, well!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.message}</i>
          </p>
        </div>
      }
    />
  );
}
