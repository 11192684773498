import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "./router";

import { SWRConfig, SWRConfiguration } from "swr";
import { AppStyleProvider } from "./components/style-provider";
import { ToastQueue } from "@react-spectrum/toast";
import { API_ERROR_RETRY_COUNT, API_DATA_REFRESH_INTERVAL } from "~/src/config";

const swrConfig: SWRConfiguration = {
  errorRetryCount: API_ERROR_RETRY_COUNT,
  refreshInterval: API_DATA_REFRESH_INTERVAL,
  shouldRetryOnError(err) {
    return err.client?.code === "ERR_NETWORK" || err.http?.code >= 500;
  },
  onError(err) {
    ToastQueue.negative(err.message, { timeout: 3000 });
  },
};

createRoot(document.getElementById("app")!).render(
  <StrictMode>
    <SWRConfig value={swrConfig}>
      <AppStyleProvider>
        <BrowserRouter />
      </AppStyleProvider>
    </SWRConfig>
  </StrictMode>
);
