import { MenuTrigger, Menu, Item, Text, Button, Divider } from "@adobe/react-spectrum";

import User from "@spectrum-icons/workflow/User";
import Logout from "@spectrum-icons/workflow/LogOut";

import { useAtom } from "jotai";

import Light from "@spectrum-icons/workflow/Light";
import Moon from "@spectrum-icons/workflow/Moon";

import storage from "../utils/storage";

import { useAppNavigator } from "../router";
import { useUserProfile } from "../hooks/useUserProfile";
import { colorSchemeAtom } from "../hooks/useColorScheme";
import { startTransition } from "react";

export function UserActionsMenu() {
  const { go } = useAppNavigator();
  const { profile } = useUserProfile();
  let [colorScheme, setColorScheme] = useAtom(colorSchemeAtom);

  const handleLogout = () => {
    startTransition(() => {
      storage.clearToken();
      go.login();
    });
  };

  return (
    <div>
      <MenuTrigger align="end">
        <Button variant="secondary" aria-label="User menu">
          <User />
          <Text>{profile.username}</Text>
        </Button>
        <Menu
          onAction={async (key) => {
            if (key === "logout") {
              handleLogout();
            } else if (key === "switch-color-scheme") {
              setColorScheme(colorScheme === "light" ? "dark" : "light");
            }
          }}
        >
          <Item key="switch-color-scheme" textValue="switch color scheme">
            {colorScheme === "dark" ? <Light aria-label="In light theme" /> : <Moon aria-label="In dark theme" />}
            <Text>{colorScheme === "dark" ? "Switch to light theme" : "Switch to dark theme"}</Text>
          </Item>
          <Item key="logout" textValue="login">
            <Logout />
            <Text>Logout</Text>
          </Item>
        </Menu>
      </MenuTrigger>
    </div>
  );
}
