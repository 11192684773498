import { request } from "./api-client";
import { AuthResponse, UserProfile } from "~/src/types/auth";
import storage from "./storage";

const getProfile = async () => {
  if (!storage.getToken()) throw new Error("No access token!");
  
    return await request<UserProfile>({
      method: "get",
      url: "/users/me",
      params: { populate: "*" },
    });
};

const connect = async (accessToken: string) => {
  return await request<AuthResponse>({
    method: "GET",
    url: "/auth/microsoft/callback",
    params: { access_token: accessToken },
  });
};

export { getProfile, connect };
