import { defaultTheme, Provider as StyleProvider } from "@adobe/react-spectrum";
import { ReactNode } from "react";
import { useColorScheme } from "../hooks/useColorScheme";

export function AppStyleProvider({ children }: { children: ReactNode }) {
  const colorScheme = useColorScheme();

  return (
    <StyleProvider
      theme={defaultTheme}
      colorScheme={colorScheme}
      UNSAFE_className={`bcc-layout--${colorScheme}`}
      UNSAFE_style={{
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </StyleProvider>
  );
}
