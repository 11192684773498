export const trim = (text: string, chars: string | string[]) => {
  let start = 0,
    end = text.length;

  if (!Array.isArray(chars)) chars = [chars];

  while (start < end && chars.indexOf(text[start]) >= 0) ++start;
  while (end > start && chars.indexOf(text[end - 1]) >= 0) --end;

  return start > 0 || end < text.length ? text.substring(start, end) : text;
};

export const truncate = (text: string, maxlength: number = 100, ending: string = "...") => {
  if (text?.length > maxlength) {
    return text.substring(0, maxlength - ending.length) + ending;
  } else {
    return text;
  }
};
