const getEnvAsNumber = (envVar: string | undefined, defaultValue: number): number => {
  const value = Number(envVar);
  return !isNaN(value) ? Number(envVar) : defaultValue;
};

export const SITE_TITLE = process.env.SITE_TITLE as string;

/**
 * Repository API endpoint root
 * @example https://repo.example.com/api
 */
export const API_URL = process.env.API_URL as string;

/**
 * Maximum number of request retry, if an API call gets a `Network Error` or 5xx
 *
 * @default 5
 */
export const API_ERROR_RETRY_COUNT = getEnvAsNumber(process.env.API_ERROR_RETRY_COUNT, 5);

/**
 * Default polling interval in milliseconds. Set this variable to `0` to disable data pooling.
 *
 * @default 300000  "(5 minutes)"
 */
export const API_DATA_REFRESH_INTERVAL = getEnvAsNumber(process.env.API_DATA_REFRESH_INTERVAL, 300000);

export const MEILISEARCH_API_ENDPOINT = process.env.MEILISEARCH_API_ENDPOINT as string;

export const MEILISEARCH_SEARCH_API_KEY = process.env.MEILISEARCH_SEARCH_API_KEY as string;
