/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

@import "../../../@adobe/spectrum-css-temp/components/commons/focus-ring.css";

.react-spectrum-ToastContainer {
  position: fixed;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 100050; /* above modals */
  display: flex;
  pointer-events: none;
  outline: none;

  .spectrum-Toast {
    position: absolute;
    margin: 16px;
    pointer-events: all;
  }

  &[data-position=top] {
    top: 0;
    flex-direction: column;
    --slide-from: translateY(-100%);
    --slide-to: translateY(0);
  }

  &[data-position=bottom] {
    bottom: 0;
    flex-direction: column-reverse;
    --slide-from: translateY(100%);
    --slide-to: translateY(0);
  }

  &[data-placement=left] {
    align-items: flex-start;
    --slide-from: translateX(-100%);
    --slide-to: translateX(0);

    &:dir(rtl) {
      --slide-from: translateX(100%);
    }
  }

  &[data-placement=center] {
    align-items: center;
  }

  &[data-placement=right] {
    align-items: flex-end;
    --slide-from: translateX(100%);
    --slide-to: translateX(0);

    &:dir(rtl) {
      --slide-from: translateX(-100%);
    }
  }
}

.spectrum-Toast {
  composes: spectrum-FocusRing;
  --spectrum-focus-ring-border-radius: var(--spectrum-toast-border-radius);
  --spectrum-focus-ring-gap: var(--spectrum-alias-focus-ring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-focus-ring-size);

  &[data-animation=entering] {
    animation: slide-in 300ms;
  }

  &[data-animation=exiting] {
    animation: fade-out 300ms forwards;
  }
}

@keyframes slide-in {
  from {
    transform: var(--slide-from);
  }

  to {
    transform: var(--slide-to);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
