import type { ColorScheme } from "@react-types/provider";
import { atomWithStorage } from "jotai/utils";
import { useAtomValue } from "jotai";

export const colorSchemeAtom = atomWithStorage<ColorScheme>(
  "colorScheme",
  window.matchMedia && window.matchMedia("(prefers-color-scheme: light)").matches ? "light" : "dark"
);

export function useColorScheme() {
  const colorScheme = useAtomValue(colorSchemeAtom);

  return colorScheme;
}
