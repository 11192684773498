import { Flex, ProgressBar, Text, ViewProps } from "@adobe/react-spectrum";
import { useStyleProps, viewStyleProps } from "@react-spectrum/utils";

export interface ContentFallbackOptions {
  label: string;
  description?: string;
  overlay?: boolean;
}

export function ContentFallback({ label, description, overlay }: ContentFallbackOptions) {
  let { styleProps } = useStyleProps<ViewProps<6>>(
    overlay
      ? {
          position: "absolute",
          top: "size-0",
          left: "size-0",
          zIndex: 2,
          backgroundColor: "default",
          width: "100%",
          height: "100%",
        }
      : { width: "100%", marginY: "size-250", backgroundColor: "default" },
    viewStyleProps
  );

  return (
    <div {...styleProps}>
      <Flex width="100%" height="100%" alignItems="center" justifyContent="center" direction="column" gap="size-200">
        <Text
          UNSAFE_style={{
            color: "var(--spectrum-alias-heading-text-color)",
            fontWeight: "var(--spectrum-alias-heading-text-font-weight-regular)",
            fontSize: "var(--spectrum-alias-heading-s-text-size)",
          }}
        >
          {label}
        </Text>
        {description && <Text slot="description">{description}</Text>}
        <ProgressBar isIndeterminate aria-label={label} />
      </Flex>
    </div>
  );
}
