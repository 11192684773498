import { trim } from "./string";

export const join = (...paths: Array<any>) => {
  return (
    "/" +
    paths
      .filter((path) => !!path)
      .map((path) => trim(path, ["/", " "]))
      .join("/")
  );
};
