import { Grid, GridProps, View } from "@adobe/react-spectrum";
import { Suspense } from "react";
import { Header } from "~/src/components/layout/header";
import { ContentFallback } from "../content-fallback";

interface LayoutProps extends Omit<GridProps, "children"> {
  center: React.ReactNode;
}
export function Layout({ center, ...props }: LayoutProps) {
  return (
    <Grid
      areas={["header", "content"]}
      rows={{ base: ["size-700", "auto"], M: ["size-900", "auto"] }}
      height="100vh"
      {...props}
    >
      <View
        gridArea="header"
        paddingX={{ base: "size-160", M: "size-250", L: "size-325" }}
        paddingY={{ base: "size-85", M: "size-160" }}
        colorVersion={6}
        borderBottomColor="light"
        borderBottomWidth="thick"
      >
        <Header />
      </View>
      <View
        gridArea="content"
        overflow="auto"
        paddingX={{ base: "size-160", M: "size-250", L: "size-325" }}
        paddingY={{ base: "size-160", M: "size-160", L: "size-250" }}
        position="relative"
      >
        <Suspense fallback={<ContentFallback label="Content loading" overlay />}>{center}</Suspense>
      </View>
    </Grid>
  );
}
