interface ErrorData<T> {
  code: T;
  message: string;
  name?: string;
}

export class ApiClientError extends Error {
  public http?: ErrorData<number>;
  public client?: ErrorData<string>;
  public api?: ErrorData<string> & { details: string | Record<string, any> };

  constructor(error: any, ...options: any) {
    super(...options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiClientError);
    }

    this.name = "ApiClientError";

    this.client = {
      code: error.code,
      name: error.name,
      message: error.message,
    };

    if (error.response) {
      this.http = {
        code: error.response.status,
        message: error.response.statusText,
      };

      if (error.response.data?.error) {
        this.api = {
          code: error.response.data.error.status,
          name: error.response.data.error.name,
          message: error.response.data.error.message,
          details: error.response.data.error.details,
        };
      }
    }

    this.message = this.api?.message ?? this.http?.message ?? this.client.message;
  }
}
