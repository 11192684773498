import { Button, Text } from "@adobe/react-spectrum";

import Trash from "@spectrum-icons/workflow/Delete";
import { useAppNavigator } from "../router";

export function TrashButton() {
  const { go } = useAppNavigator();

  return (
    <div>
      <Button
        variant="secondary"
        aria-label="Trash"
        onPress={() => {
          go.trash();
        }}
      >
        <Trash />
        <Text>Trash</Text>
      </Button>
    </div>
  );
}
