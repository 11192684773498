import { axios } from "~/src/lib/axios";
import { ApiClientError } from "~/src/utils/api-client/error";
import { MutateArg, FetchParams, MutateActions } from "~/src/utils/api-client/types";
import { AxiosRequestConfig, AxiosProgressEvent } from "axios";
import { File as ApiFile } from "~/src/types/api";

export async function request<T>(config: AxiosRequestConfig<MutateArg<T, keyof T>["data"]>) {
  try {
    return (await axios<T>(config)) as T;
  } catch (error: any) {
    throw new ApiClientError(error);
  }
}

type Key<T> = string | { url: string; params?: FetchParams<T extends Array<infer Item> ? Item : T> };

export type WrappedResponse<T> = {
  data: T;
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};

export async function fetcher<T>(key: Key<T>, params?: FetchParams<T extends Array<infer Item> ? Item : T>): Promise<T>;
export async function fetcher<T>(
  key: Key<T>,
  params: FetchParams<T extends Array<infer Item> ? Item : T>,
  includeResponseMeta: true
): Promise<WrappedResponse<T>>;
export async function fetcher<T>(
  key: Key<T>,
  params?: FetchParams<T extends Array<infer Item> ? Item : T>,
  includeResponseMeta?: boolean
): Promise<T | WrappedResponse<T>> {
  const response = await request<WrappedResponse<T>>(
    typeof key === "string"
      ? {
          method: "get",
          url: key,
          params,
        }
      : {
          method: "get",
          url: key.url,
          params: { ...params, ...key.params },
        }
  );

  return includeResponseMeta === true ? response : response.data;
}

interface MutateActionConfig {
  method: "post" | "put" | "delete";
  path?: string;
}
const mutateActionConfigs: Record<MutateActions, MutateActionConfig> = {
  create: { method: "post" },
  update: { method: "put" },
  delete: { method: "delete" },
  publish: { method: "put", path: "/publish" },
  unpublish: { method: "put", path: "/unpublish" },
  archive: { method: "put", path: "/archive" },
  restore: { method: "put", path: "/restore" },
  addChildren: { method: "put", path: "/add-children" },
  removeChildren: { method: "put", path: "/remove-children" },
};

export async function mutator<T>(key: Key<T>, { arg }: { arg: MutateArg<T, keyof T> }) {
  const url = arg.entityUrl ?? (typeof key === "string" ? key : key.url);
  const response = await request<WrappedResponse<T>>({
    url: url + (mutateActionConfigs[arg.action].path ?? ""),
    method: mutateActionConfigs[arg.action].method,
    data: arg.action !== "delete" ? arg.data : undefined,
    params: arg.action === "delete" ? arg.data : undefined,
  });

  return response.data;
}

interface FileUploadOptions {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  signal?: AbortSignal;
  path?: string;
}

export async function uploadFile(file: File, options?: FileUploadOptions) {
  const formData = new FormData();
  formData.append("files", file);

  if (options?.path) {
    const path = options.path.trim();
    if (!!path) formData.append("path", path);
  }

  try {
    const response = (await axios({
      url: "upload",
      method: "post",
      data: formData,
      timeout: 0,
      signal: options?.signal,
      onUploadProgress: options?.onUploadProgress,
    })) as ApiFile[];

    return response[0];
  } catch (error: any) {
    throw new ApiClientError(error);
  }
}

export async function removeFile(fileId: number) {
  try {
    const response = (await axios({
      url: `upload/files/${fileId}`,
      method: "delete",
    })) as ApiFile;

    return response;
  } catch (error: any) {
    throw new ApiClientError(error);
  }
}

export type { MutateArg, ApiClientError, FileUploadOptions };
