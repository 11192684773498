import { Key, lazy, useMemo } from "react";
import { createBrowserRouter, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import ErrorPage from "~/src/error";
import { BreadcrumbsItem, BreadcrumbsStateChange } from "~/src/types/breadcumbs";
import { join } from "~/src/utils/path";
import { API_URL } from "./config";

const App = lazy(async () => import("~/src/app.tsx"));
const Connect = lazy(async () => import("~/src/connect.tsx"));

const RepositoryNavigator = lazy(async () => import("~/src/features/repository-navigator/index.ts"));
const ResourceEditor = lazy(async () => import("~/src/features/resource-editor/index.ts"));

const AddResourceWizard = lazy(async () => import("~/src/features/add-resource-wizard/index.ts"));

const RepositoryBrowser = lazy(async () => {
  const feature = await import("~/src/features/repository-browser/index.ts");
  return { default: feature.RepositoryBrowser };
});

const RepositoryTrash = lazy(async () => {
  const feature = await import("~/src/features/repository-trash/index.ts");
  return { default: feature.RepositoryTrash };
});

const router = createBrowserRouter([
  {
    path: "/connect",
    children: [
      {
        index: true,
        element: <Connect />,
      },
      {
        path: "microsoft",
        element: <Connect />,
      },
    ],
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <RepositoryNavigator
            filters={[
              { type: "concept-intersect-resource-types", id: 773 },
              { type: "concept-intersect-concepts", id: 774 },
            ]}
          />
        ),
      },
      {
        path: "resources",
        children: [
          {
            path: ":filterBy/:filterId",
            element: <RepositoryBrowser />,
          },
          {
            path: "add",
            element: <AddResourceWizard />,
          },
          {
            path: ":id/edit/*",
            element: <ResourceEditor />,
          },
        ],
      },
      {
        path: "browse",
        children: [
          {
            path: "resource-types/:id/resources",
            element: <RepositoryBrowser />,
          },
          {
            path: "concepts/:id/resources",
            element: <RepositoryBrowser />,
          },
        ],
      },
      {
        path: "trash",
        children: [
          {
            index: true,
            element: <RepositoryTrash />,
          },
        ],
      },
    ],
  },
]);

export function BrowserRouter() {
  return <RouterProvider router={router} />;
}

const getNewLocationState = (currState?: BreadcrumbsItem[], newState?: BreadcrumbsStateChange) => {
  if (!newState) return undefined;
  if (newState.data !== undefined && !Array.isArray(newState.data)) newState.data = [newState.data];
  switch (newState.op) {
    case "set":
      return newState.data;
    case "append":
      if (currState) return [...currState, ...newState.data];
      else return undefined;
    default:
      return undefined;
  }
};

interface AppNavigationOptions {
  state?: BreadcrumbsStateChange;
  replace?: boolean;

  /**
   * Specified path will be appended to the URL
   */
  relativePath?: string;

  /**
   * If `true`, the function will return the url without navigating to it
   */
  urlOnly?: boolean;
}

export function useAppNavigator() {
  const navigate = useNavigate();
  let { state: currState }: { state?: BreadcrumbsItem[] } = useLocation();

  const go = useMemo(
    () => ({
      back: () => navigate(-1),
      oAuthConnect: () => {
        window.location.href = `${API_URL}/connect/microsoft`;
      },
      login: (options: AppNavigationOptions = { urlOnly: false }) => {
        const url = join("/connect", options.relativePath);
        if (options.urlOnly !== true)
          navigate(url, { replace: options.replace, state: getNewLocationState(currState, options.state) });
        return url;
      },
      home: (options: AppNavigationOptions = { urlOnly: false }) => {
        const url = join("/", options.relativePath);
        if (options.urlOnly !== true)
          navigate(url, { replace: options.replace, state: getNewLocationState(currState, options.state) });
        return url;
      },
      trash: (options: AppNavigationOptions = { urlOnly: false }) => {
        const url = join("/trash", options.relativePath);
        if (options.urlOnly !== true)
          navigate(url, { replace: options.replace, state: getNewLocationState(currState, options.state) });
        return url;
      },
      getResourcesByType: (typeId: Key, options: AppNavigationOptions = { urlOnly: false }) => {
        const url = join(`/browse/resource-types/${typeId}/resources`, options.relativePath);
        if (options.urlOnly !== true)
          navigate(url, { replace: options.replace, state: getNewLocationState(currState, options.state) });
        return url;
      },
      getResourcesByConcept: (conceptId: Key, options: AppNavigationOptions = { urlOnly: false }) => {
        const url = join(`/browse/concepts/${conceptId}/resources`, options.relativePath);
        if (options.urlOnly !== true)
          navigate(url, { replace: options.replace, state: getNewLocationState(currState, options.state) });

        return url;
      },
      editResource: (resourceId: Key, options: AppNavigationOptions = { urlOnly: false }) => {
        const url = join(`/resources/${resourceId}/edit`, options.relativePath);

        if (options.urlOnly !== true)
          navigate(url, { replace: options.replace, state: getNewLocationState(currState, options.state) });
        return url;
      },
      addResource: (options: AppNavigationOptions = { urlOnly: false }) => {
        const url = join("/resources/add", options.relativePath);
        if (options.urlOnly !== true)
          navigate(url, { replace: options.replace, state: getNewLocationState(currState, options.state) });
        return url;
      },
    }),
    [currState]
  );

  return { go };
}
