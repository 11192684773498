// @ts-ignore
import Axios, { AxiosInstance } from "axios";
import type { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { stringify } from "qs";
import { API_URL } from "~/src/config";
import storage from "~/src/utils/storage";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
  timeout: 10000,
  paramsSerializer: (params: Record<string, any>) => stringify(params, { encodeValuesOnly: true }),
}) as AxiosInstance;

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);
