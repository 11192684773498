import { Flex, Heading, View } from "@adobe/react-spectrum";

import { SITE_TITLE } from "~/src/config";
import { UserActionsMenu } from "~/src/components/user-menu";
import { TrashButton } from "~/src/components/trash-button";

export function Header() {
  return (
    <Flex alignItems="center" justifyContent={"center"} gap="size-160" height="100%">
      <View colorVersion={6} overflow="hidden" flex="auto" height="100%">
        <Heading level={1} margin="size-0">
          {SITE_TITLE}
        </Heading>
      </View>
      <TrashButton />
      <UserActionsMenu />
    </Flex>
  );
}
